import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-settings cards-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patience = _resolveComponent("patience")!
  const _component_it_tab = _resolveComponent("it-tab")!
  const _component_around_the_clock = _resolveComponent("around-the-clock")!
  const _component_solitaire = _resolveComponent("solitaire")!
  const _component_it_tabs = _resolveComponent("it-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_it_tabs, { class: "tabs-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_it_tab, { title: "One-Handed Solitaire" }, {
          default: _withCtx(() => [
            _createVNode(_component_patience)
          ]),
          _: 1
        }),
        _createVNode(_component_it_tab, { title: "Clock Solitaire" }, {
          default: _withCtx(() => [
            _createVNode(_component_around_the_clock)
          ]),
          _: 1
        }),
        _createVNode(_component_it_tab, { title: "Solitaire" }, {
          default: _withCtx(() => [
            _createVNode(_component_solitaire)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}