import { Rank } from './rank'

export default class HiddenCardSet {
    rank: Rank
    count: number

    constructor (rank: Rank, count: number) {
      this.rank = rank
      this.count = count
    }
}
