export default class Suit {
    name: string
    icon: string
    colour: string
    secondaryColour: string

    constructor (name: string, icon: string, colour: string, secondaryColour: string) {
      this.name = name
      this.icon = icon
      this.colour = colour
      this.secondaryColour = secondaryColour
    }
}
