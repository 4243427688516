import Deck from './deck'
import Card from './card'

export default class ClockDeck extends Deck {
  usedCards: Card[] = []

  newGameSetup () : void {
    this.usedCards = []
    this.initializeDeck()
    this.shuffleDeck()
  }
}
