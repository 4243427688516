
import { Options, Vue } from 'vue-class-component'
import MoveTable from '@components/cards/move-table.vue'
import AdvancedOptions from '@models/advancedOptions'
import Card from '@models/card'
import PatienceDeck from '@models/patienceDeck'

@Options({
  components: {
    'move-table': MoveTable
  }
})
export default class HandPatience extends Vue {
  deck: PatienceDeck = new PatienceDeck()
  easy = false;
  tutorial = true;
  finishModal = false;
  rulesModal = false;
  advancedModal = false;
  moveList = false;
  startGame = false;
  onHoverDiscardHand = false;
  onHoverDiscardCore = false;
  advancedOptions = new AdvancedOptions();
  rules1Deck: Card[] = [];
  rules2Deck: Card[] = [];
  rules3Deck: Card[] = [];

  created (): void {
    this.deck.createDeck(this.advancedOptions)

    this.rules1Deck = [
      this.deck.cards[50],
      this.deck.cards[39],
      this.deck.cards[6],
      this.deck.cards[37]
    ]

    this.rules2Deck = [
      this.deck.cards[2],
      this.deck.cards[34],
      this.deck.cards[22],
      this.deck.cards[7]
    ]

    this.rules3Deck = [
      this.deck.cards[11],
      this.deck.cards[22],
      this.deck.cards[33],
      this.deck.cards[44]
    ]
    this.deck.shuffleDeck()
  }

  discard () : void {
    this.deck.discard()
    if (this.tutorial && !this.canDiscardSet) {
      this.onHoverDiscardHand = false
    }
  }

  next () : void {
    this.deck.next()
  }

  finish () : void {
    this.finishModal = false
    this.$nextTick(() => {
      this.restart()
    })
  }

  cheaterFinish () : void {
    this.moveList = true
    this.finishModal = false
  }

  discardCentral () : void {
    this.deck.discardWithoutEnds()
    if (this.tutorial && !this.canDiscardCentral) {
      this.onHoverDiscardCore = false
    }
  }

  calculateHover (index: number) : boolean {
    return (index > 0 && index < this.deck.cardsInPlay - 1 && this.onHoverDiscardCore) || this.onHoverDiscardHand
  }

  restart () : void {
    this.deck = new PatienceDeck()
    this.deck.createDeck(this.advancedOptions)
    this.deck.shuffleDeck()
  }

  resetOptions () : void {
    this.advancedOptions = new AdvancedOptions()
  }

  openRules () : void {
    this.rulesModal = true
  }

  get isAtMaximum (): boolean {
    return this.deck.index + this.visibleCards.length === this.deck.cards.length
  }

  get remainingCards (): number {
    return this.deck.cards.length - this.deck.index - this.visibleCards.length
  }

  get visibleCards (): Card[] {
    if (this.deck.cards.length >= this.deck.cardsInPlay) {
      return this.deck.cards.slice(this.deck.index, this.deck.index + this.deck.cardsInPlay)
    } else {
      return this.deck.cards.slice(this.deck.index, this.deck.cards.length)
    }
  }

  get lastCard () : Card | null {
    return this.deck.index > 0 ? this.deck.cards[this.deck.index - 1] : null
  }

  get canDiscardCentral () : boolean {
    return this.deck.canDiscardCentral()
  }

  get canDiscardSet () : boolean {
    return this.deck.canDiscardSet()
  }

  get score () : number {
    return this.deck.score
  }

  get winner () : boolean {
    return this.score > 48
  }

  get loser () : boolean {
    return this.score < 10
  }
}
