
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    moves: Array,
    title: String
  }
})
export default class MoveTable extends Vue {
}
