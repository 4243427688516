
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Card from '@models/card'
import { Rank } from '@models/rank'
import ClockDeck from '@models/clockDeck'
import HiddenCardSet from '@models/hiddenCardSet'

@Options({
  components: {
  }
})
export default class AroundTheClock extends Vue {
  tutorial = true;
  finishModal = false;
  rulesModal = false;
  startGame = false;
  stats = false;
  deck = new ClockDeck();

  @Watch('gameOver')
  onGameOverChanged (newValue: boolean) : void {
    if (this.startGame && newValue) {
      setTimeout(() => {
        this.finishModal = true
      }, 1000)
    }
  }

  created () : void {
    this.deck.newGameSetup()
  }

  animate (rank: Rank) : void {
    if (this.topCard.rank === rank) {
      var card = this.deck.cards.pop()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.deck.usedCards.push(card!)
    }
  }

  closeRules (): void {
    this.rulesModal = false
    this.startGame = true
  }

  finish () : void {
    this.finishModal = false
    this.$nextTick(() => {
      this.restart()
    })
  }

  openRules (): void {
    this.rulesModal = true
  }

  restart () : void {
    this.deck.newGameSetup()
    this.stats = false
  }

  toggleStats () : void {
    this.stats = !this.stats
  }

  get gameOver () : boolean {
    return this.topCard && this.topCard.rank === Rank.KING && this.hiddenCards[12].count === 0
  }

  get score (): number {
    return this.deck.cards.length
  }

  get topCard () : Card {
    return this.deck.cards[this.deck.cards.length - 1]
  }

  get winner (): boolean {
    return this.score === 1 && this.gameOver
  }

  get hiddenCards () : HiddenCardSet[] {
    return [
      new HiddenCardSet(Rank.ACE, this.deck.cards.filter(x => x.rank === Rank.ACE).length),
      new HiddenCardSet(Rank.TWO, this.deck.cards.filter(x => x.rank === Rank.TWO).length),
      new HiddenCardSet(Rank.THREE, this.deck.cards.filter(x => x.rank === Rank.THREE).length),
      new HiddenCardSet(Rank.FOUR, this.deck.cards.filter(x => x.rank === Rank.FOUR).length),
      new HiddenCardSet(Rank.FIVE, this.deck.cards.filter(x => x.rank === Rank.FIVE).length),
      new HiddenCardSet(Rank.SIX, this.deck.cards.filter(x => x.rank === Rank.SIX).length),
      new HiddenCardSet(Rank.SEVEN, this.deck.cards.filter(x => x.rank === Rank.SEVEN).length),
      new HiddenCardSet(Rank.EIGHT, this.deck.cards.filter(x => x.rank === Rank.EIGHT).length),
      new HiddenCardSet(Rank.NINE, this.deck.cards.filter(x => x.rank === Rank.NINE).length),
      new HiddenCardSet(Rank.TEN, this.deck.cards.filter(x => x.rank === Rank.TEN).length),
      new HiddenCardSet(Rank.JACK, this.deck.cards.filter(x => x.rank === Rank.JACK).length),
      new HiddenCardSet(Rank.QUEEN, this.deck.cards.filter(x => x.rank === Rank.QUEEN).length),
      new HiddenCardSet(Rank.KING, this.deck.cards.filter(x => x.rank === Rank.KING).length - 1)
    ]
  }

  get visibleCards () : Card[][] {
    return [
      this.deck.usedCards.filter(x => x.rank === Rank.ACE),
      this.deck.usedCards.filter(x => x.rank === Rank.TWO),
      this.deck.usedCards.filter(x => x.rank === Rank.THREE),
      this.deck.usedCards.filter(x => x.rank === Rank.FOUR),
      this.deck.usedCards.filter(x => x.rank === Rank.FIVE),
      this.deck.usedCards.filter(x => x.rank === Rank.SIX),
      this.deck.usedCards.filter(x => x.rank === Rank.SEVEN),
      this.deck.usedCards.filter(x => x.rank === Rank.EIGHT),
      this.deck.usedCards.filter(x => x.rank === Rank.NINE),
      this.deck.usedCards.filter(x => x.rank === Rank.TEN),
      this.deck.usedCards.filter(x => x.rank === Rank.JACK),
      this.deck.usedCards.filter(x => x.rank === Rank.QUEEN),
      this.deck.usedCards.filter(x => x.rank === Rank.KING)
    ]
  }

  // Test Method
  reanimate () : void {
    if (this.deck.cards.length > 0 && !this.gameOver) {
      var card = this.deck.cards.pop()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.deck.usedCards.push(card!)
    }
  }
}
