import Suit from './suit'
import { Rank } from './rank'

export default class Card {
    suit: Suit
    rank: Rank

    constructor (suit: Suit, rank: Rank) {
      this.suit = suit
      this.rank = rank
    }

    toString () : string {
      return this.suit.icon + this.rank
    }
}
