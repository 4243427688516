import Suit from './suit'
import { Rank } from './rank'
import Card from './card'

export default class Deck {
    cards: Card[] = [];
    suits: Suit[] = [
      new Suit('Hearts', '\u2665', 'red', '#800000'),
      new Suit('Clubs', '\u2663', 'black', '#828282'),
      new Suit('Diamonds', '\u2666', 'red', '#800000'),
      new Suit('Spades', '\u2660', 'black', '#828282')
    ]

    ranks: Rank[] = [
      Rank.ACE,
      Rank.TWO,
      Rank.THREE,
      Rank.FOUR,
      Rank.FIVE,
      Rank.SIX,
      Rank.SEVEN,
      Rank.EIGHT,
      Rank.NINE,
      Rank.TEN,
      Rank.JACK,
      Rank.QUEEN,
      Rank.KING
    ];

    initializeDeck () : void {
      this.cards = []
      for (const suit of this.suits) {
        for (const rank of this.ranks) {
          this.cards.push(new Card(suit, rank))
        }
      }
    }

    shuffleDeck (): void {
      let currentIndex = this.cards.length
      let randomIndex

      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--;
        // And swap it with the current element.
        [this.cards[currentIndex], this.cards[randomIndex]] = [
          this.cards[randomIndex], this.cards[currentIndex]]
      }
    }

    toString (): string {
      let deck = ''
      // eslint-disable-next-line no-return-assign
      this.cards.forEach(x => deck += x.toString() + ', ')
      return deck
    }
}
