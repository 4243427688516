import Card from './card'
import { Action } from './action'
import { MoveCategory } from './moveCategory'
import Move from './move'
import Deck from './deck'
import AdvancedOptions from './advancedOptions'
import Suit from './suit'
import { Rank } from './rank'

export default class PatienceDeck extends Deck {
    discarded: Card[] = [];
    moves: Move[] = [];
    index = 0;
    cardsInPlay = 4;
    score = 0;
    cheater = false;

    createDeck (advancedOptions: AdvancedOptions): void {
      super.initializeDeck()
      for (let i = 0; i < advancedOptions.jokers; i++) {
        this.cards.push(
          new Card(
            new Suit('JOKER', '', 'blue', '#7CB9E8'),
            Rank.JOKER
          )
        )
      }
    }

    moveIndex (increment: number): void {
      if (this.index + this.cardsInPlay + increment > this.cards.length) {
        this.index = this.cards.length - this.cardsInPlay
      } else {
        this.index += increment
      }
    }

    showTop () : string {
      let deck = ''
      for (let i = this.index; i < this.index + this.cardsInPlay; i++) {
        deck += this.cards[i].toString() + ', '
      }
      return deck.substring(0, deck.length - 2)
    }

    discard (): void {
      if (this.cardsInPlay <= this.cards.length) {
        // Track Move
        const optimumMove = this.checkCurrentMove()

        switch (optimumMove) {
          case Action.RemoveSet :
            this.trackMove(new Move(true, Action.RemoveSet))
            break
          default :
            this.trackMove(new Move(false, Action.RemoveSet, MoveCategory.InvalidMove, optimumMove))
        }

        // actually discard move
        for (let i = this.index; i < this.index + this.cardsInPlay; i++) {
          const card = this.cards[this.index]
          this.cards.splice(this.index, 1)
          this.discarded.push(card)
        }

        // move index back
        this.index -= this.cardsInPlay

        // stop negative index
        if (this.index < 0) {
          this.index = 0
        }
      }
    }

    checkCurrentMove () : Action {
      if (this.canDiscardSet()) {
        return Action.RemoveSet
      } else if (this.canDiscardCentral()) {
        return Action.RemoveCentral
      } else {
        return Action.Pass
      }
    }

    get jokerIsInPlay () : boolean {
      if (this.cards.length > this.cardsInPlay) {
        return this.cards[this.index].rank === Rank.JOKER ||
          this.cards[this.index + this.cardsInPlay - 1].rank === Rank.JOKER
      }
      return false
    }

    trackMove (move: Move) : void {
      for (let i = this.index; i < this.index + this.cardsInPlay; i++) {
        move.cards.push(this.cards[i])
      }
      this.moves.push(move)

      if (move.valid && move.action === Action.RemoveCentral) {
        this.score += this.cardsInPlay - 2
      } else if (move.valid && move.action === Action.RemoveSet) {
        this.score += this.cardsInPlay
      }

      if (!move.valid) {
        this.cheater = true
      }
    }

    discardWithoutEnds (): void {
      if (this.cardsInPlay <= this.cards.length) {
        // Track failings
        const optimumMove = this.checkCurrentMove()

        switch (optimumMove) {
          case Action.RemoveCentral :
            this.trackMove(new Move(true, Action.RemoveCentral))
            break
          default :
            this.trackMove(new Move(false, Action.RemoveCentral, this.jokerIsInPlay ? MoveCategory.JokerMove : MoveCategory.InvalidMove, optimumMove))
        }

        for (let i = this.index; i < this.index + this.cardsInPlay - 2; i++) {
          const card = this.cards[this.index + 1]
          this.cards.splice(this.index + 1, 1)
          this.discarded.push(card)
        }

        this.index -= this.cardsInPlay - 2

        if (this.index < 0) {
          this.index = 0
        }
      }
    }

    next () : void {
      const optimumMove = this.checkCurrentMove()

      switch (optimumMove) {
        case Action.Pass :
          this.trackMove(new Move(true, Action.Pass))
          break
        default :
          this.trackMove(new Move(true, Action.Pass, MoveCategory.Missed, optimumMove))
          break
      }

      this.moveIndex(1)
    }

    canDiscardCentral (index = this.index) : boolean {
      return this.cards[index].suit === this.cards[index + this.cardsInPlay - 1].suit
    }

    canDiscardSet (index = this.index) : boolean {
      return this.cards[index].rank === this.cards[index + this.cardsInPlay - 1].rank
    }
}
