import { Action } from './action'
import Card from './card'
import { MoveCategory } from './moveCategory'

export default class Move {
    cards: Card[] // 1-2-3-4
    action: Action // Next / Remove central / Remove set
    valid: boolean // Valid / Invalid
    moveCategory?: MoveCategory // Missed / illegal
    optimumAction?: Action

    constructor (valid: boolean, action: Action, moveCategory?: MoveCategory, optimumAction?: Action) {
      this.valid = valid
      this.action = action
      this.moveCategory = moveCategory
      this.optimumAction = optimumAction
      this.cards = []
    }

    toString () : string {
      let deck = ''
      // eslint-disable-next-line no-return-assign
      this.cards.forEach(x => deck += x.toString() + ', ')
      return deck.substring(0, deck.length - 2)
    }
}
