
import { Options, Vue } from 'vue-class-component'
import Patience from '@components/cards/hand-patience.vue'
import AroundTheClock from '@components/cards/around-the-clock.vue'
import Solitaire from '@components/cards/klondike.vue'

@Options({
  components: {
    Patience,
    Solitaire,
    'around-the-clock': AroundTheClock
  }
})
export default class CardsMenu extends Vue {
}
